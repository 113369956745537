import * as React from 'react'
import { Link } from "gatsby"
import Layout from '/src/components/layout'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLanguage, faPlug, faQuestionCircle, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'
import { chargersStatusValues } from '/src/components/support.js'

const LaddkontakterPage = () => {
    return (
        <Layout pageTitle="Laddkontakter &amp; färger">


            <h2>Laddkontakter</h2>
            <p>Vid laddning av elbilar finns det olika typer av kontakter och uttag vilket beror på bilmärke och typ av laddning (destinations-/snabbladdning).</p>
            <table><tbody>
                <tr>
                    <td>Kontakt:</td>
                    <td>Förklaring:</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}><img src="/connectors/ccs.svg" alt="Ikon för CSS" style={{width: '50px'}} /></td>
                    <td>
                        <b>CCS</b><br/>
                        EU-standard för snabb- &amp; supersnabbladdning. Med CCS är det laddstolpen som omvandlar från växel- till likström. Vanligtvis upp till 50-150 kW men toppmodeller av laddare klarar upp till 250-350 kW. Olika bilar klarar att ta emot olika snabbt. Kabel sitter i princip alltid fast i laddpunkten och därmed ingen kabel du behöver (eller kan) ha med dig själv.
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}><img src="/connectors/type2.svg" alt="Ikon för Typ 2" style={{width: '50px'}} /></td>
                    <td>
                        <b>Typ 2</b><br/>
                        EU-standard för destinationsladdning. Kallas ibland även Mennekes vilket är ett företag som tillverkar många av typ 2-kablarna. De flesta kablar klarar laddning upp till 22 kW vilket långt ifrån alla ombordladdare klarar. Med typ 2 är det bilens ombordsladdare som omvandlar från växel- till likström. Ibland sitter kabeln fast i laddpunkten men oftast behöver du själv ha med dig en typ 2-kabel.
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}><img src="/connectors/chademo.svg" alt="Ikon för CHAdeMO" style={{width: '50px'}} /></td>
                    <td>
                        <b>CHAdeMO</b><br/>
                        En standard för snabbladdning som återfinns framförallt på bilar av asiatiska märken. CHAdeMO brukar vara ett långsammare alternativ än CCS och återfinns inte på lika många laddstationer.
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}><img src="/connectors/schuko.svg" alt="Ikon för Schuko" style={{width: '50px'}} /></td>
                    <td>
                        <b>Schuko</b><br/>
                        Ett klassiskt vägguttag. Är inte gjort för elbilsladdning och rekommenderas <u>inte</u> för detta ändamål. Om uttaget ändå används bör laddning uppgå till max 8-10 A enligt Elsäkerhetsmyndigheten. Läs mer <a href="https://www.elsakerhetsverket.se/globalassets/publikationer/broschyrer/ladda-elbilen.pdf" target="_blank">här</a>. Vid laddning med 8 A (&amp; 230 V) blir effekten cirka 1,8 kW.
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}><img src="/connectors/cee.svg" alt="Ikon för CEE" style={{width: '50px'}} /></td>
                    <td>
                        <b>CEE</b><br/>
                        Finns i olika utförande, exempelvis både som en och tre fas, och är en industrikontakt (<a href="https://sv.wikipedia.org/wiki/IEC_60309" target="_blank">IEC_60309</a>). Återfinns ofta på campingar och kallas ibland "campingkontakt".
                    </td>
                </tr>
            </tbody></table>

            <h2>Statusfärger</h2>
            <p>De flesta publika laddpunkter lyser i en färg för att visa sin status och i de flesta fall betyder färgerna samma sak. Även färger på statusar i appar brukar följa denna "standard".</p>
            <table><tbody>
                <tr>
                    <td>Färg:</td>
                    <td>Status:</td>
                </tr>
                <tr>
                    <td style={{fontSize: '32px', textAlign: 'center'}}>{chargersStatusValues['available']}</td>
                    <td>Laddpunkten/boxen/uttaget är <b>tillgänglig/ledig</b> för någon att börja ladda.</td>
                </tr>
                <tr>
                    <td style={{fontSize: '32px', textAlign: 'center'}}>{chargersStatusValues['occupied']}</td>
                    <td>Laddpunkten/boxen/uttaget är <b>upptagen/används</b> av någon som har en aktiv laddsession.</td>
                </tr>
                <tr>
                    <td style={{fontSize: '32px', textAlign: 'center'}}>{chargersStatusValues['outOfOrder']}</td>
                    <td>Laddpunkten/boxen/uttaget <b>fungerar inte</b> vilket kan bero på flera anledningar.</td>
                </tr>
            </tbody></table>
            
        </Layout>
    )
}

export default LaddkontakterPage